import styled from 'styled-components';
import { mediaQuery, setColors, setFontSize } from '../../../../utils/helpers';

export const Wrapper = styled.div`
	margin: 0 -15px;
	display: grid;
	grid-template-columns: 5fr 7fr;

	@media only screen and (min-width: 300px) and (max-width: 768px) {
		.hide {
			display: none;
		}
	}

	.content {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		justify-content: center;
		padding: 0 15px;
	}
	.hide {
		padding: 0 15px;
	}

	p {
		font-size: ${setFontSize.normalAverage};
		text-align: left;
		margin: 0;
	}

	h2 {
		font-size: ${setFontSize.title};
		text-align: left;
		color: ${setColors.lightBlack};
		margin: 0;
		padding-bottom: 6%;
	}
	${mediaQuery.tabletXS`
		grid-template-columns: 1fr;
		.content{
			align-items: center;
		}
		h2{
			text-align: center;
		}
		p{
			text-align: center;
		}
	`};
`;
