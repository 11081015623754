import { Link } from 'gatsby';
import styled from 'styled-components';
import {
	media,
	setColors,
	setFontFamily,
	setFontSize,
	setFontWeight,
	flexPosition,
	mediaQuery,
} from '../../../../utils/helpers';

export const Header = styled.header`
	display: grid;
	justify-items: center;
	padding: 0 15px 0px 15px;
	${media.tablet`
		padding: 0 15px 0px 15px;
	`};
`;

export const StyledGradientLeft = styled.div`
	position: absolute;
	width: 1037.72px;
	height: 1203.05px;
	left: 786.97px;
	top: -219px;

	background: linear-gradient(139.03deg, #2a7cff 56.3%, #28f2ff 82.09%);
	filter: blur(100px);
	transform: matrix(-1, 0.06, 0.06, 1, 0, 0);
`;

export const StyledGradientRight = styled.div`
	position: absolute;
	width: 1037.72px;
	height: 1203.05px;
	left: 732.9px;
	top: -219px;

	background: linear-gradient(138.35deg, #28f2ff 57.7%, #2a7cff 94.09%);
	filter: blur(100px);
	transform: rotate(3.33deg);
`;

export const Section = styled.div`
	text-align: center;
	max-width: ${props => (props.isHomePageHeader ? '1170px' : '850px')};
	margin-top: 35px;
	color: ${props => props.isHomePageHeader && setColors.white};
	h1 {
		font-family: ${props =>
			props.isHomePageHeader ? setFontFamily.book : setFontFamily.black};
		font-weight: ${setFontWeight.extraBold};
		font-size: ${props =>
			props.isHomePageHeader ? setFontSize.medium : setFontSize.large};
		line-height: 1.11;
		letter-spacing: -1.5px;
		margin: 0 !important;
		margin-bottom: 30px !important;
	}
	p {
		font-size: ${props =>
			props.isHomePageHeader ? setFontSize.tiny : setFontSize.mediumTiny};
		font-weight: ${props =>
			props.isHomePageHeader ? setFontWeight.normal450 : setFontWeight.normal};
		line-height: ${props => (props.isHomePageHeader ? '27px' : '1.3')};
		font-family: ${props =>
			props.isHomePageHeader ? setFontFamily.book : setColors.lightBlack};
		margin: ${props =>
			props.isHomePageHeader ? '15px 0px !important' : '0.85em 0 !important'};
	}

	${media.tablet`
		margin-top: 70px;
		h1{
			font-size: ${props =>
				props.isHomePageHeader ? setFontSize.larg60 : setFontSize.tinyLarge};
		}
	`}

	${media.desktop`
		margin-top: ${props => (props.isHomePageHeader ? '70px' : '35px')};
		h1 {
			font-size: ${props =>
				props.isHomePageHeader ? setFontSize.largest : setFontSize.larger};
		}
	`};

	${media.large`
		margin-top: ${props => (props.isHomePageHeader ? '100px' : '35px')};
		h1{
			font-size: ${props =>
				props.isHomePageHeader
					? setFontSize.largerLarge
					: setFontSize.extraLarge};
		}
		p{
			font-size: ${props =>
				props.isHomePageHeader ? setFontSize.tiny : setFontSize.verySmall};
		}
  	`};
`;

export const ButtonHolder = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-top: 40px;
	${media.tablet`
		margin-top:30px;
		flex-direction: row;
	`}
	.heroButton {
		margin: 0 11.5px;
		${mediaQuery.mobileXL`
			margin: 10px 0;
			min-width: 218px;
		`}
	}
`;

export const Button = styled(Link)`
	padding: ${props => (props.blue ? '12px 25px' : `10px 23px`)};
	box-sizing: border-box !important;
	${flexPosition.center};
	border-radius: 4px;
	font-size: ${setFontSize.mediumTiny};
	text-decoration: none;
	line-height: 16px;
	letter-spacing: normal;
	color: ${props => (props.blue ? setColors.white : `${setColors.themeBlue}`)};
	background-image: ${props =>
		props.blue
			? `linear-gradient(to bottom, ${setColors.darkBlueGradiant}, ${setColors.lightBlueGradiant})`
			: setColors.white};
	background-color: transparent;
	border: ${props =>
		props.blue ? 0 : `2px solid ${setColors.darkBlueGradiant}`};
	box-shadow: 0px 6px 15px 0 rgb(31 117 2 55 / 20%);
	line-height: 1;
	outline: none;
	margin: 12px 0;

	${media.tablet`
		margin: 0 12px;
	`}

	&:hover {
		border: ${props => (props.blue ? 0 : 0)};
		background-image: linear-gradient(
			to bottom,
			${setColors.darkBlueGradiant},
			${setColors.lightBlueGradiant}
		);
		padding: ${props => (props.blue ? '12px 25px' : `12px 25px`)};
		color: ${props => (props.blue ? setColors.white : setColors.white)};
	}
`;

export const ImageHolder = styled.div`
	margin: 30px 0 0 0;
`;

export const EllipseWrapper = styled.div`
	height: 144px;
	background: ${setColors.solitude};
`;

export const Ellipse = styled.div`
	background: ${setColors.lightBlack};
	height: 144px;
	width: 100%;
	clip-path: ellipse(54vw 100% at top center);
`;
