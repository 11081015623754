import styled from 'styled-components';
import {
	media,
	mediaQuery,
	setColors,
	setFontFamily,
	setFontSize,
} from '../../../utils/helpers';
import image from '../../images/bg/background-image-3.png';
import bottomImage from '../../images/bg/background-image-2.png';
import image3 from '../../images/bg/background-image-1.png';

export const ContentBoxContainer = styled.div`
	padding-top: 100px;
	padding-bottom: 100px;
	${mediaQuery.tabletL`
		padding-top: 40px;
	`}
	${mediaQuery.tabletXS`
		padding-top: 50px;
		border: none !important;
	`}
`;

export const HR = styled.hr`
	border-top: 1px solid ${setColors.lightestGray};
`;

export const Commitment = styled.p`
	margin: 0;
	padding: 60px 0;
	border-bottom: 1px solid ${setColors.whitishGray} !important;
	${mediaQuery.tabletL`
		padding: 40px 0;
	`}
	${mediaQuery.tabletXS`
		padding: 41px 0 50px 0;
		text-align: center;
	`}
`;

export const TeamWrapper = styled.section`
	padding: 60px 0 0 0;
	h2 {
		margin-bottom: 0;
	}
	${mediaQuery.tabletL`
		padding: 40px 0;
	`}
	${mediaQuery.tabletXS`
		padding: 50px 0 50px 0;
		text-align: center;
	`}
`;
export const TitleWrapper = styled.div`
	padding-bottom: 4%;
	${mediaQuery.tabletL`
		padding-bottom: 2%;
	`}
`;

export const Team = styled.div`
	&:before,
	&:after {
		content: '';
		display: table;
	}
	&:after {
		clear: both;
	}
`;
export const BgWrapper = styled.div`
	background-image: url('${image}'), url('${bottomImage}'), url('${image3}');
	background-position: 100% -3px, 0% 1481px, 100% 2520px;
	background-size: 52% auto;
	z-index: -1;
	background-repeat: no-repeat;
	margin-top: 2px;
	${mediaQuery.mobileXL`background-image: none;margin-top: 0`};

	.aboutCarousalWrapperClass {
		${mediaQuery.tabletL`
			display: block;
		`}
	}
	.aboutCarousalContentClass {
		${mediaQuery.tabletL`
			margin: 0 auto;
		`}
	}
	.aboutCareerWrapperClass {
		${mediaQuery.mobileM`
			display: block;
		`}
	}
`;

export const SectionWrapper = styled.div`
	margin: 0;
	margin-top: 50px;
	${mediaQuery.mobileXL`
        margin: 0;
        margin-bottom: 20px;
    `}
`;

export const CTAWrapper = styled.div`
	margin-bottom: 20px;
`;

export const Title = styled.div`
	color: ${setColors.lightBlack};
	font-size: ${setFontSize.large};
	margin: 0 auto;
	text-align: center;
	font-family: ${setFontFamily.black};

	${media.phone`
		font-size: ${setFontSize.large};
		margin: 0 auto;
	`}
	${media.tablet`
		font-size: ${setFontSize.tinyMedium};
		margin: 80px auto 0;
	`}
	${media.desktop`
		font-size: ${setFontSize.mediumLarge};
		margin: 80px auto 0;
	`}
	${media.large`
		font-size: ${setFontSize.larger};
		margin: 80px auto 0;
	`}
`;

export const SubSection = styled.div`
	color: ${setColors.lightBlack};
	font-size: ${setFontSize.veryTiny};
	font-family: ${setFontFamily.book};
	text-align: center;
	margin: 20px 30px;
	${media.phone`
		font-size: ${setFontSize.veryTiny};
	`}
	${media.tablet`
		font-size: ${setFontSize.mediumTiny};
	`}
	${media.desktop`
		font-size: ${setFontSize.tiny};
	`}
	${media.large`
		font-size: ${setFontSize.tinySmall};
	`}
`;

export const ImageWrapper = styled.div`
	${({ theme }) => theme.marginAuto};
	padding: 30px 0;
	width: 100%;
	box-sizing: border-box;
	${mediaQuery.mobileXL`display: ${props => props.hide && 'none'}`}
`;

export const ContainerTitle = styled.div`
	color: ${setColors.lightBlack};
	margin: 0 auto;
	text-align: center;
	font-family: ${setFontFamily.black};
	font-size: ${setFontSize.large};
	${media.phone`
		font-size: ${setFontSize.large};
	`}
	${media.tablet`
		font-size: ${setFontSize.large};
	`}
	${media.desktop`
		font-size: ${setFontSize.tinyMedium};
	`}
	${media.large`
		font-size: ${setFontSize.mediumLarge};
	`}
`;

export const ContainerSubSection = styled.div`
	color: ${setColors.lightBlack};
	font-size: ${setFontSize.veryTiny};
	font-family: ${setFontFamily.book};
	text-align: center;
	margin: 28px auto 28px;
	${media.phone`
		font-size: ${setFontSize.veryTiny};
	`}
	${media.tablet`
		font-size: ${setFontSize.mediumTiny};
	`}
	${media.desktop`
		font-size: ${setFontSize.tiny};
	`}
	${media.large`
		font-size: ${setFontSize.tinySmall};
	`}
`;

export const ContainerSubSection1 = styled.div`
	margin-bottom: 25px;
`;

export const ContainerSubSection2 = styled.div`
	padding: 0 35px;
`;

export const LearnMoreTitleContainer = styled.div`
	color: ${setColors.lightBlack};
	margin: 60px auto 50px;
	text-align: center;
	font-family: ${setFontFamily.black};
	font-size: ${setFontSize.large};
	${media.small`
		font-size: ${setFontSize.large};
		margin: 100px auto 50px;
	`}
	${media.phone`
		font-size: ${setFontSize.large};
		margin: 100px auto 50px;
	`}
	${media.tablet`
		font-size: ${setFontSize.large};
		margin: 60px auto 50px;
	`}
	${media.desktop`
		font-size: ${setFontSize.tinyMedium};
		margin: 60px auto 50px;
	`}
	${media.large`
		font-size: ${setFontSize.mediumLarge};
		margin: 60px auto 50px;
	`}
`;

export const ImageSlider = styled.div`
	padding: 0 15px;
	margin: 0 auto;
	.slick-list {
		border-radius: 15px;
		max-height: 370px;
		max-width: 370px;
	}
	.slick-dots {
		bottom: -45px;
		width: 100%;
		margin: 0;
		list-style: none;
		text-align: center;
	}
	.slick-dots li {
		position: relative;
		display: inline-block;
		width: 20px;
		height: 20px;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;
	}
	.slick-dots button {
		font-size: 0;
		line-height: 0;
		display: block;
		width: 20px;
		height: 20px;
		padding: 5px;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: 0;
		background: 0 0;
	}
	.slick-active button {
		color: black;
	}
	.slick-dots li button:focus:before,
	.slick-dots li button:hover:before {
		opacity: 0.25;
	}
	.slick-dots li button:before {
		opacity: 0.25;
	}
	.slick-dots li.slick-active button:before {
		font-size: 6px;
		line-height: 20px;
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		content: '•';
		text-align: center;
		opacity: 0.75;
		color: #000;
	}
	.slick-arrow {
		display: none !important;
	}
	${media.tablet`
		padding: 0 15px;
		
		.slick-list {
			border-radius: 15px;
			max-height: 400px;
			max-width: 536px;
		}
		.slick-track{
			width: 3752px;
		}
		.slick-dots {
			bottom: -45px;
    		width: 100%;
    		margin: 0;
    		list-style: none;
    		text-align: center;
		}
		.slick-dots li {
			position: relative;
    		display: inline-block;
    		width: 20px;
    		height: 20px;
    		margin: 0 5px;
    		padding: 0;
    		cursor: pointer;
		}
		.slick-dots li button {
			font-size: 0;
    		line-height: 0;
    		display: block;
    		width: 20px;
    		height: 20px;
    		padding: 5px;
    		cursor: pointer;
    		color: transparent;
    		border: 0;
    		outline: 0;
    		background: 0 0;
		}
		.slick-dots li button:focus:before, .slick-dots li button:hover:before{
			opacity: 0.25;
		}
		.slick-dots li button:before{
			font-size: 6px;
    		line-height: 20px;
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 20px;
    		height: 20px;
    		content: '•';
    		text-align: center;
    		opacity: .25;
    		color: #000;
		}
		.slick-dots li.slick-active button:before {
    		opacity: .75;
			color: #000
		}
		.slick-arrow{
			display: none !important;
		}
	`};
	${media.desktop`
		padding: 0 15px;
		
		.slick-list {
			border-radius: 15px;
			max-height: 500px;
			max-width: 640px;
		}
		.slick-track{
			width: 4480px;
		}
		.slick-dots {
			bottom: -45px;
    		width: 100%;
    		margin: 0;
    		list-style: none;
    		text-align: center;
		}
		.slick-dots li {
			position: relative;
    		display: inline-block;
    		width: 20px;
    		height: 20px;
    		margin: 0 5px;
    		padding: 0;
    		cursor: pointer;
		}
		.slick-dots li button {
			font-size: 0;
    		line-height: 0;
    		display: block;
    		width: 20px;
    		height: 20px;
    		padding: 5px;
    		cursor: pointer;
    		color: transparent;
    		border: 0;
    		outline: 0;
    		background: 0 0;
		}
		.slick-dots li button:focus:before, .slick-dots li button:hover:before{
			opacity: 0.25;
		}
		.slick-dots li button:before{
			font-size: 6px;
    		line-height: 20px;
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 20px;
    		height: 20px;
    		content: '•';
    		text-align: center;
    		opacity: .25;
    		color: #000;
		}
		.slick-dots li.slick-active button:before {
    		opacity: .75;
			color: #000
		}
		.slick-arrow{
			display: none !important;
		}
	`};
	${media.large`
		padding: 0 15px;
		
		.slick-list {
			border-radius: 15px;
			max-height: 500px;
			max-width: 936px;
		}
		.slick-track{
			width: 6552px;
		}
		.slick-dots {
			bottom: -45px;
    		width: 100%;
    		margin: 0;
    		list-style: none;
    		text-align: center;
		}
		.slick-dots li {
			position: relative;
    		display: inline-block;
    		width: 20px;
    		height: 20px;
    		margin: 0 5px;
    		padding: 0;
    		cursor: pointer;
		}
		.slick-dots li button {
			font-size: 0;
    		line-height: 0;
    		display: block;
    		width: 20px;
    		height: 20px;
    		padding: 5px;
    		cursor: pointer;
    		color: transparent;
    		border: 0;
    		outline: 0;
    		background: 0 0;
		}
		.slick-dots li button:focus:before, .slick-dots li button:hover:before{
			opacity: 0.25;
		}
		.slick-dots li button:before{
			font-size: 6px;
    		line-height: 20px;
    		position: absolute;
    		top: 0;
    		left: 0;
    		width: 20px;
    		height: 20px;
    		content: '•';
    		text-align: center;
    		opacity: .25;
    		color: #000;
		}
		.slick-dots li.slick-active button:before {
    		opacity: .75;
			color: #000
		}
		.slick-arrow{
			display: none !important;
		}
	`};
`;

export const WrapperSection = styled.section`
	background-color: ${setColors.warmGray};
	padding: 100px 0;
`;
