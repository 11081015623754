import React from 'react';
import Img from 'gatsby-image';
import * as S from './styles';
import { Button } from '../../home-page/header/styles';
import '../../../fonts/typography.css';

const ContentBox = ({ title, subtitle, image, button }) => {
	return (
		<S.Wrapper>
			<div className="content">
				<h1
					className="font-black-54"
					dangerouslySetInnerHTML={{ __html: title }}
				></h1>
				<p className="font-book-20">{subtitle}</p>

				{button && (
					<Button blue="true" to="/">
						Home page
					</Button>
				)}
			</div>
			<div className="hide">
				<Img
					fluid={image.fluid}
					alt={image.title}
					imgStyle={{ borderRadius: '10px' }}
				/>
			</div>
		</S.Wrapper>
	);
};

export default ContentBox;
