import React from 'react';
import Img from 'gatsby-image';

function ImageWrapper({ image, imageStyles, otherStyles }) {
	return (
		<>
			{image.file?.contentType ? (
				<>
					{image.file.contentType === 'image/svg+xml' ||
					image.file.contentType === 'image/gif' ? (
						<img
							src={image.file.url}
							style={{
								borderRadius: '10px',
								width: '100%',
								...imageStyles,
								...otherStyles,
							}}
							alt={image.title}
						/>
					) : image.file.contentType === 'video/mp4' ? (
						<video
							autoPlay
							muted
							loop
							playsInline="playsInline"
							style={{
								objectFit: 'cover',
								borderRadius: '10px',
								width: '100%',
								...imageStyles,
								...otherStyles,
							}}
						>
							<source src={image.file.url} type={image.file.contentType} />
						</video>
					) : (
						<Img
							fluid={image.fluid}
							alt={image.title}
							imgStyle={{
								borderRadius: '10px',
								width: '100%',
								...imageStyles,
							}}
							style={{ ...otherStyles }}
						/>
					)}
				</>
			) : (
				<>
					<Img
						fluid={image.fluid}
						alt={image.title}
						imgStyle={{ borderRadius: '10px', width: '100%', ...imageStyles }}
						style={{ ...otherStyles }}
					/>
				</>
			)}
		</>
	);
}

export default ImageWrapper;
