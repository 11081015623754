import styled from 'styled-components';
import { mediaQuery } from '../../../../utils/helpers';

export const Wrapper = styled.div`
	padding: ${props => props.padding && `${props.padding}px 0 0 0`};

	${mediaQuery.mobileXL`
        padding: ${props => props.padding && `${props.padding / 2}px 0 0 0`};
    `};
`;
