import { graphql } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import ContentBox from '../components/global/content-box/ContentBox';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import * as S from '../components/about-us-page/styles';
import SEO from '../components/global/seo';
import Padding from '../components/global/Padding/Padding';
import SlickCSS from '../components/about-us-page/slick-css';
import { Container } from '../components/global/customers/styles';
import ImageWrapper from '../components/global/ImageWrapper/ImageWrapper';
import { InfoCardGrid } from '../components/global/InfoCard/InfoCard.styled';
import InfoCard from '../components/global/InfoCard/InfoCard.component';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import CustomerHolder from '../components/customers-page/customer-holder/CustomerHolder';

const About = ({ data }) => {
	const {
		section1Image,
		section1Subtitle,
		section1Title,
		section2Tilte,
		newSection2Subtitle,
		section2Image,
		section4Image1,
		section4Image1Info,
		section4Image2,
		section4Image2Info,
		section4Image3,
		section4Image3Info,
		section4Title,
		section6Title,
		section6Subtitle,
		section6ImageCarousel,
		learnMoreTitle,
		learnMoreCards,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		seoJsonSchema,
		cta,
	} = data.allContentfulAboutPage.nodes[0];

	const settings = {
		dots: true,
		fade: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		focusOnSelect: true,
		cssEase: 'linear',
	};

	const customers = [];
	customers.push(
		{
			cardImage: section4Image1,
			data: section4Image1Info,
		},
		{
			cardImage: section4Image2,
			data: section4Image2Info,
		},
		{
			cardImage: section4Image3,
			data: section4Image3Info,
		},
	);

	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				image={seoImage}
				article={seoArticle}
				seoJsonSchema={seoJsonSchema}
			/>
			<SlickCSS />
			<S.ContentBoxContainer>
				<WrapperOne>
					<ContentBox
						title={section1Title}
						subtitle={section1Subtitle}
						image={section1Image}
					/>
				</WrapperOne>
			</S.ContentBoxContainer>
			<WrapperOne>
				<Container>
					<S.Title>{section2Tilte}</S.Title>
					<S.SubSection>
						<p>{newSection2Subtitle.newSection2Subtitle}</p>
					</S.SubSection>
					<ImageWrapper
						image={section2Image}
						otherStyles={{
							maxWidth: '900px',
							margin: '0 auto',
							width: '100%',
						}}
					/>
				</Container>
				<S.TeamWrapper>
					<S.Team>
						<CustomerHolder
							title={section4Title}
							customers={customers}
							isLeadershipSection
						/>
					</S.Team>
				</S.TeamWrapper>
			</WrapperOne>
			<S.WrapperSection>
				<Container>
					<S.ContainerTitle>{section6Title}</S.ContainerTitle>
					<S.ContainerSubSection>
						<S.ContainerSubSection1>
							{section6Subtitle[0].text}
						</S.ContainerSubSection1>
						{section6Subtitle[1]?.text && (
							<S.ContainerSubSection2>
								{section6Subtitle[1].text}
							</S.ContainerSubSection2>
						)}
					</S.ContainerSubSection>
					<S.ImageSlider>
						<Slider {...settings}>
							{section6ImageCarousel.map((image, index) => (
								<Img fluid={image.fluid} key={index} alt={image.title} />
							))}
						</Slider>
					</S.ImageSlider>
				</Container>
			</S.WrapperSection>
			<WrapperOne wrapperClass="aboutCareerWrapperClass">
				<Container margin={10}>
					<S.LearnMoreTitleContainer>
						{learnMoreTitle}
					</S.LearnMoreTitleContainer>
					<InfoCardGrid style={{ marginTop: '20px' }}>
						{learnMoreCards.map(card => (
							<InfoCard
								image={card.icon}
								title={card.title}
								subtile={card.description.description}
								linkText={card.linkText}
								linkUrl={card.linkUrl}
							/>
						))}
					</InfoCardGrid>
				</Container>
			</WrapperOne>
			<WrapperOne disableBottomMargin="true">
				<Padding padding="70" />
				<S.CTAWrapper>
					<MasterCTA cta={cta} />
				</S.CTAWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	query {
		allContentfulAboutPage {
			nodes {
				section1Subtitle
				section1Title
				section1Image {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				section2Tilte
				newSection2Subtitle {
					newSection2Subtitle
				}
				section2Image {
					file {
						contentType
						url
					}
					fluid {
						...GatsbyContentfulFluid
					}
					title
					description
				}
				section4Image1 {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				section4Title
				section4Image1Info {
					content
					twitter
					title
					name
					linkedin
				}
				section4Image2 {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				section4Image2Info {
					content
					twitter
					title
					linkedin
					name
				}
				section4Image3 {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				section4Image3Info {
					content
					twitter
					title
					linkedin
					name
				}
				section6Title
				section6Subtitle {
					text
					id
				}
				section6ImageCarousel {
					fluid {
						...GatsbyContentfulFluid
					}
					title
				}
				learnMoreTitle
				learnMoreCards {
					title
					description {
						description
					}
					icon {
						file {
							url
						}
						title
					}
					linkText
					linkUrl
				}
				cta {
					...cta
				}
				seoTitle
				seoDescription
				seoArticle
				seoImage {
					title
					file {
						url
						details {
							image {
								height
								width
							}
						}
					}
				}
				seoJsonSchema {
					internal {
						content
					}
				}
			}
		}
	}
`;
export default About;
