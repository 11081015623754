import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
	title,
	description,
	image,
	article,
	noIndex,
	seoJsonSchema,
}) => {
	const { pathname } = useLocation();
	const { site } = useStaticQuery(query);
	const {
		defaultTitle,
		defaultDescription,
		siteUrl,
		defaultImage,
		twitterUsername,
	} = site.siteMetadata;
	const seo = {
		title: title || defaultTitle,
		description: description || defaultDescription,
		image: image || `${siteUrl}${defaultImage}`,
		url: `${siteUrl}${pathname}`,
		noIndex: noIndex,
	};
	const imageUrl = `https:${seo.image.file.url}`;
	const seoJsonSchemaString = seoJsonSchema?.internal?.content;
	return (
		<Helmet title={seo.title}>
			<meta name="description" content={seo.description} />
			{seo.image && <meta name="image" content={imageUrl} />}
			{seo.url && <meta property="og:url" content={seo.url} />}

			{article ? (
				<meta property="og:type" content="article" />
			) : (
				<meta property="og:type" content="website" />
			)}

			{seo.title && <meta property="og:title" content={seo.title} />}

			{seo.description && (
				<meta property="og:description" content={seo.description} />
			)}

			{seo.image && <meta property="og:image" content={imageUrl} />}
			{seo.image && <meta property="og:image:alt" content={seo.image.title} />}
			{seo.image && (
				<meta
					property="og:image:width"
					content={seo.image.file.details.image.width}
				/>
			)}
			{seo.image && (
				<meta
					property="og:image:height"
					content={seo.image.file.details.image.height}
				/>
			)}

			<meta name="twitter:card" content="summary_large_image" />

			{twitterUsername && (
				<meta name="twitter:creator" content={twitterUsername} />
			)}

			{seo.title && <meta name="twitter:title" content={seo.title} />}

			{seo.description && (
				<meta name="twitter:description" content={seo.description} />
			)}

			{seo.image && <meta name="twitter:image" content={imageUrl} />}
			{seo.noIndex && <meta name="robots" content="noindex" />}
			{seoJsonSchemaString &&
				Object.keys(JSON.parse(seoJsonSchemaString)).length !== 0 && (
					<script type="application/ld+json" id="contentful_json_schema">
						{JSON.stringify(JSON.parse(seoJsonSchemaString), null, 2)}
					</script>
				)}
		</Helmet>
	);
};

export default SEO;

SEO.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	image: PropTypes.string,
	article: PropTypes.bool,
};

SEO.defaultProps = {
	title: null,
	description: null,
	image: null,
	article: false,
};

const query = graphql`
	query SEO {
		site {
			siteMetadata {
				defaultTitle: title
				defaultDescription: description
				siteUrl: url
				defaultImage: image
				twitterUsername
			}
		}
	}
`;
